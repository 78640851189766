<template>
  <!-- 官方文件 -->
  <div>
    <!-- 头部 -->
    <Footer></Footer>
    <!-- 内容区域 -->
    <div class="content" style="min-height: 500px;background:#E5E9F3;">
        <div class="content_box">
			<div style="margin:35px 0px;" v-html="txtHtml"></div>
         
        </div>
    </div>
    <!-- 底部 -->
    <Bottom></Bottom>
  </div>
</template>
  
<script>
// @ is an alias to /src
import Footer from '@/components/Footer/Footer.vue'
import Bottom from '@/components/Bottom/Bottom.vue'
import $ from "jquery";
import {article} from "@/api/apis.js"

export default {
  name: 'Home',
  components: {
    Footer,
    Bottom,
  },
  data() {
    return {
		newData:[],
		txtHtml:''
    }
  },
  created(){
	  this.details();
  },
  methods: {
	//新闻详情
	details(){	
		let queryId = this.$route.query.queryId;
		console.log(queryId)
		let getobj = new FormData();
		getobj.append("oc_id",queryId);
		article.getNewsDetails(getobj).then((res) => {
			let str = JSON.parse(res.data)
			console.log(str);
			this.txtHtml = str.content;
			// 图片地址获取方式
			// this.txtHtml = '<p><img src="https://picture-file-public-read-dt.oss-cn-hangzhou.aliyuncs.com/a/IMG_0716.JPG" style="max-width:100%;"/><br/></p>'
			console.log(this.txtHtml)
		});
	},
   
    openfile5(){
      window.open('./static/WenJ/第九届“大唐杯”全国大学生新一代信息通信技术大赛-章程.pdf')
    },
    skip3(){
      this.$router.push('/Ninth')
    },
  },

}
</script>
  
<style scoped lang="scss">
/deep/ .el-table .cell.el-tooltip{
  text-align: left;
}
/deep/.el-link__inner{
  color: #000;
  font-size: .16rem;
}
/deep/.el-link__inner:hover{
  color: #FE850F;
}
@import './File.scss'
</style>